.fade-in-image {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.fade-out-image {
  animation: fadeOut 2s;
  -webkit-animation: fadeOut 2s;
  -moz-animation: fadeOut 2s;
  -o-animation: fadeOut 2s;
  -ms-animation: fadeOut 2s;
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}


.glitch {
    margin: 0;
    line-height: 0;
    
    animation: glitch1 0.7s 1s;
  }
  
  .glitch:nth-child(2) {
    animation: glitch2 0.7s 1s;
  }
  
  .glitch:nth-child(3) {
    animation: glitch3 0.7s 1s;
  }
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
    10% {
      transform: none;
      opacity: 1;
    }
    27% {
      transform: none;
      opacity: 1;
    }
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
    35% {
      transform: none;
      opacity: 1;
    }
    52% {
      transform: none;
      opacity: 1;
    }
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
    50% {
      transform: none;
      opacity: 1;
    }
    72% {
      transform: none;
      opacity: 1;
    }
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
    80% {
      transform: none;
      opacity: 1;
    }
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
    10% {
      transform: none;
      opacity: 0.25;
    }
    27% {
      transform: none;
      opacity: 0.25;
    }
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
    35% {
      transform: none;
      opacity: 0.25;
    }
    52% {
      transform: none;
      opacity: 0.25;
    }
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
    50% {
      transform: none;
      opacity: 0.25;
    }
    72% {
      transform: none;
      opacity: 0.25;
    }
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
    80% {
      transform: none;
      opacity: 0.25;
    }
    100% {
      transform: none;
      opacity: 0.25;
    }
  }

  .ehre {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
}

.myButton {
  background-color: #3a3a3a;
  border-radius: 30px;
  box-shadow: 10px 10px 20px -8px rgba(0, 0, 0,.7);
}


.myButton:hover {
  background-color: #000274;
  box-shadow: none;
}


.titel {

  color: #444;
  font-size: 200%;
  
  @media (max-width: 1000px) {
    font-size: 22px;
  }

  @media (max-width: 650px) {
    font-size: 11px;
  }
  
}

.Anfrage {
  color: #000;
  font-family: Archivo Black;
  // font-size: 1.8vw;
  margin-left: 1.4vw;
}

// .AnfrageButton {
//   background: #26925c;
//   border-radius: 70px;
//   padding: 0.72vw 0.82vw;
// }

// .AnfrageButton:hover {
//   background: #000;
//   border-radius: 70px;
//   padding: 0.72vw 0.82vw;

//   .Anfrage {
//     color: #fff;
//   }
// }

.AnfrageButton {
  background-color: #26925c;
  border-radius: 30px;
  transition: all 0.3s;
  // padding: 1em 1em;
  box-shadow: 10px 10px 20px -8px rgba(0, 0, 0,.7);
  text-align: center;
  transition: 0.3s;
}


.AnfrageButton:after {
  content: '   \27A4 ';
  font-weight: bold;
  font-size: 1.8vw;
  color: #000;
  //position: absolute;
  opacity: 0;  
  right: -20px;
  transition: 0.5s;
}

.AnfrageButton:hover{
  padding-right: 24px;
  padding-left:8px;
  cursor: pointer;
  box-shadow: none;
}

.AnfrageButton:hover:after {
  opacity: 1;
  right: 10px;
}

.slider-split-line {
  --divider-width: 0.3vw;
}

.pointer {
  pointer-events: all !important;
}

@keyframes floaty {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-2vh);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(2vh);
	}
	100% {
		transform: translatey(0px);
	}
}

.floating {
  transform: translatex(0px);
	animation: float 3s ease-in-out infinite;
}

.imagestyle {
  box-shadow: 3px 5px 10px silver;
  border-radius: 0.6vw;
  width: 60%;
  height: 60%;
  position: relative;
  z-index: 6;
}
.imagestyle:hover {
  box-shadow: 3px 5px 10px silver;
  border-radius: 0.6vw;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
}

.imagestyle2 {
  box-shadow: 3px 5px 10px silver;
  border-radius: 0.6vw;
  width: 60%;
  height: 60%;
  position: relative;
  margin-left: 25%;
  margin-top: -16%;
  z-index: 7;
}
.imagestyle2:hover {
  box-shadow: 3px 5px 10px silver;
  border-radius: 0.6vw;
  width: 100%;
  height: 100%;
  margin-left: -16%;
  position: relative;
  z-index: 7;
}

.btun,
aboutbutton {
  background: #1d1d1d;
  color: white;
  border: none;
  font-size: 10px;
  font-size: 1rem;
  background-color: #1d1d1d;
  color: white;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 14px 21px;
  // padding: 1.4rem 2.1rem;
  border: 2px solid #1d1d1d;
  transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1), background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
  display: inline-block;
  cursor: pointer;
  // width: 100%;
  outline: none;
  vertical-align: middle;
  text-align: center;
  position: relative;
  overflow: hidden;
  @media (min-width: 400px) {
    width: auto;
  }
  @media (min-width: 800px) {
    font-size: 1.1rem;
    padding: 1.6rem 2.8rem;
  }
  &:hover {
    background: lighten(#1d1d1d, 5%);
    border-color: lighten(#1d1d1d, 5%);
    color: #fff;
  }
}

.btun-border {
  background-color: transparent;
  color: #1d1d1d;
}

.btun-arrow {
  position: relative;
    transition: background-color 300ms ease-out;
  span {
    display: inline-block;
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
  }
  &:hover span {
    transform: translate3d(-1rem, 0, 0);
  }
  svg {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
    * {
      stroke-width: 5;
    }
  }
  &:hover svg {
    opacity: 1;
    right: -2rem;
  }
}

body {
  height: 100%;
  min-height: 100%;
  color: #000;
  background-color: #fff;
}


#invertedcursor {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  top: var(--y, 0);
  left: var(--x, 0);
  transform: translate(-50%, -50%);
  z-index: 200;
  mix-blend-mode: difference;
  transition: transform .2s;
}

@media (min-width: 1200px) {
  .textbox {
    width: 32%;
    height: 28vw;
    background-color: #fff;
    transition: transform .2s;
    padding: 20px 20px;
    color: #000;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);

  }

  .icon-v {
    background-image: url("../images/icons2/vorteile-2.png");
    width: 200px;
    height: 200px;
  }
  
  .icon-s {
    background-image: url("../images/icons2/icon-service-2.png");
    width: 200px;
    height: 200px;
  }
  
  .icon-p {
    background-image: url("../images/icons2/kosten-2.png");
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 1200px) {
  .textbox {
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    transition: transform .2s;
    padding: 20px 20px;
    color: #000;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);

  }

  .icon-v {
    background-image: url("../images/icons2/vorteile-2.png");
    width: 100px;
    height: 100px;
    background-size: cover;
  }
  
  .icon-s {
    background-image: url("../images/icons2/icon-service-2.png");
    width: 100px;
    height: 100px;
    background-size: cover;
  }
  
  .icon-p {
    background-image: url("../images/icons2/kosten-2.png");
    width: 100px;
    height: 100px;
    background-size: cover;
  }
}

@media (min-width: 600px) {
  .textbox {
    width: 32%;
    height: 50vh;
    background-color: #fff;
    transition: transform .2s;
    padding: 20px 20px;
    color: #000;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);

  }

  .icon-v {
    background-image: url("../images/icons2/vorteile-2.png");
    width: 130px;
    height: 130px;
    background-size: cover;
  }
  
  .icon-s {
    background-image: url("../images/icons2/icon-service-2.png");
    width: 130px;
    height: 130px;
    background-size: cover;
  }
  
  .icon-p {
    background-image: url("../images/icons2/kosten-2.png");
    width: 130px;
    height: 130px;
    background-size: cover;
  }
}

.textbox:hover {
  transform: scale(1.05);
  color: #1ea76a;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

  .icovi {
    display: none;
  }
}


.headline::before {
  position: absolute;
  content: "";
  width: 70px;
  height: 2px;
  background-color: #1ea568;
}


.icons {
  transition: transform .2s;
}

.icons:hover {
  transform: scale(1.2);
}

.icons2 {
  transition: transform .2s;
}

.icons2:hover {
  transform: scale(1.1);
}

@media (min-width: 1920px) {
  .carcontainer>img {
    min-width: 100vw;
  }
}

.carcontainer img {
  min-width: 100vw;
}

.imagestylenotilt {
  box-shadow: 3px 5px 10px silver;
  border-radius: 0.6vw;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 6;
}

.imagestylenotilt1 {
  box-shadow: 3px 5px 10px silver;
  border-radius: 0.6vw;
  width: 60%;
  height: 60%;
  position: relative;
  z-index: 6;
}

.imagestylenotilt2 {
  box-shadow: 3px 5px 10px silver;
  border-radius: 0.6vw;
  width: 60%;
  height: 60%;
  margin-left: 25%;
  margin-top: -16%;
  position: relative;
  z-index: 7;
}