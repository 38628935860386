@media only screen and (min-width: 949px) {
    
    
    #consent-popup {
/*        text-align: center;*/
        position: relative;
        width: 700px;
        top: 12.5%;
        margin: 0 auto;
        z-index: 99;
        padding: 18px 3rem;
        background-color: #fff;
        transition: opacity 500ms ease;
        box-shadow: 0 6px 15px 6px rgba(0,0,0,0.5);
        border-radius: 3px;
    
    }
    
    .cookie-head {
        font-family: 'Arimo';
        text-decoration: none;
        font-size: 9vh;
        margin-top: 3vh;
        margin-bottom: 1.6rem;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 9vh;
    
    }

    #cookie-section h3:nth-child(3), #cookie-settings p {
        font-size: 23px;
    }
}

@media only screen and (max-width: 949px) {
    
    #consent-popup {
        position: fixed;
        width: 100%;
        top: 12.5%;
        left: 0;
        right: 0;
        z-index: 99;
        padding: 0 3rem;
        background-color: #fff;
        transition: opacity 500ms ease;
        box-shadow: 0 6px 15px 6px rgba(0,0,0,0.5);
        border-radius: 3px;
    
    }
    
    .cookie-head {
        font-family: 'Arimo';
        text-decoration: none;
        font-size: 6vh;
        margin-top: 3vh;
        margin-bottom: 1.6rem;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 6vh;
    
    }

    #cookie-section h3:nth-child(3), #cookie-settings p {
        font-size: 19px;
    }
}

@media only screen and (max-width: 400px) {
    
    #consent-popup {
        position: fixed;
        width: 100%;
        top: 12.5%;
        left: 0;
        right: 0;
        z-index: 99;
        padding: 2rem 1rem;
        background-color: #fff;
        transition: opacity 500ms ease;
        box-shadow: 0 6px 15px 6px rgba(0,0,0,0.5);
        border-radius: 3px;
    
    }
    
    .cookie-head {
        font-family: 'Arimo';
        text-decoration: none;
        font-size: 6vh;
        margin-top: 3vh;
        margin-bottom: 1.6rem;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 6vh;
    
    }

    #cookie-section h3:nth-child(3), #cookie-settings p {
        font-size: 17px;
    }
    
}

#consent-popup.hidden {
    opacity: 0;
}

#cookie-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    z-index: 9999999;
    display: var(--bs-google-visible);
}

a {
    
    text-decoration: none;
}


.btn-cookies {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
    background: #1ea76a;
    text-align: center;
    padding: 1rem 0;
    border-radius: 4px;
}

.text-cookies {
    margin-right: 1em;
    margin-top: 1em;
    color: #000;
    font-weight: 700;
}

/*
#deny:before {
    content: "\00d7";
    color: #ff0000;
    font-size: 3em;
    font-weight: bold;
    border: solid 3px;
    text-align: right;
    padding: 2px 14px;
}
*/

#cookie-section * {
    font-family: var(--bs-font-sans-serif) !important;
}

.active {
    display: inherit;
    opacity: 1;
}

.hide {
    display: none;
    opacity: 0;
}

@font-face {
    font-family: "Open Sans";
    font-display: swap;
    src: url(../../assets/fonts/WOFF2/OpenSans-VariableFont_wdth\,wght.woff2) format("woff2");
}

@font-face {
    font-family: "Rubik";
    font-display: swap;
    src: url(../../assets/fonts/WOFF2/Rubik-VariableFont_wght.woff2) format("woff2");
}

@font-face {
    font-family: "Inter";
    font-display: swap;
    src: url(../../assets/fonts/WOFF2/Inter-VariableFont_slnt\,wght.woff2) format("woff2");
}

@font-face {
    font-family: "Archivo Black";
    font-display: swap;
    src: url(../../assets/fonts/WOFF2/ArchivoBlack-Regular.woff2) format("woff2");
}